<template>
	<div>
		<a-modal v-model:visible="visible" width="800px">
			<template #title>
				<modalHeader title="下账申请"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button v-permission="['pc_inventory_lossManage_query']" @click="onSubmit" type="primary">确认下账</a-button>
			</template>
			<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formModal" :label-col="{ span: 6 }">
				<a-row>
					<a-col :span="13">
						<a-form-item label="盘亏资产">
							{{formModal.name}}
							<!-- <a-input v-model:value="formModal.name"  placeholder="请输入盘亏资产编号或资产名称"></a-input> -->
						</a-form-item>
						
						<a-form-item label="理由">
							{{reason}}
							<!-- <a-input placeholder="请输入理由"></a-input> -->
							<!-- <a-select v-model:value="formModal.cause" allow-clear style="width: 190px;">
								<a-select-option :value="1">盘亏</a-select-option>
							</a-select> -->
						</a-form-item>
						
						<a-form-item label="会计凭证号">
							<!-- <a-input v-model:value="formModal.voucherNo" placeholder="请输入会计凭证号"></a-input> -->
							{{formModal.voucherNo}}
						</a-form-item>
						
						<a-form-item label="补充说明">
							<!-- <a-input v-model:value="formModal.explain" placeholder="请输入补充说明"></a-input> -->
							{{formModal.explain}}
						</a-form-item>
						
						<a-form-item label="申请资料">
							<a target="_blank" :href="formModal.materialUrl">查看资料</a>
							<!-- <filesUpload v-model:value="fileList"></filesUpload>
							<p>注：建议上传pdf格式的文件，大小不超过100M</p> -->
						</a-form-item>
					</a-col>
					<a-col :span="11">
						<div class="ui-box">
							<a-form-item label="资产编号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.code}}
							</a-form-item>
							<a-form-item label="资产分类名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.classifyName}}
							</a-form-item>
							<a-form-item label="资产名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.name}}
							</a-form-item>
							<a-form-item label="取得方式" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.gainType}} -->
								<div v-if="showFormModal.gainType === 1">新购</div>
								<div v-if="showFormModal.gainType === 2">挑拨</div>
								<div v-if="showFormModal.gainType === 3">接收捐献</div>
								<div v-if="showFormModal.gainType === 4">自建</div>
								<div v-if="showFormModal.gainType === 5">置换</div>
								<div v-if="showFormModal.gainType === 6">盘盈</div>
								<div v-if="showFormModal.gainType === 7">自行研制</div>
								<div v-if="showFormModal.gainType === 8">其他</div>
							</a-form-item>
							<a-form-item label="取得日期" class="ui-result__item" :label-col="{ span: 9 }">
								{{transDateTime(showFormModal.gainTime)}}
							</a-form-item>
							<a-form-item label="设备用途" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.useType}} -->
								<div v-if="showFormModal.useType === 1">办公</div>
								<div v-if="showFormModal.useType === 2">教学</div>
								<div v-if="showFormModal.useType === 3">培训</div>
								<div v-if="showFormModal.useType === 4">执法</div>
								<div v-if="showFormModal.useType === 5">窗口</div>
								<div v-if="showFormModal.useType === 6">专用</div>
								<div v-if="showFormModal.useType === 7">其他</div>
								<div v-if="showFormModal.useType === 8">医疗</div>
								<div v-if="showFormModal.useType === 9">科研</div>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.model}}
							</a-form-item>
							<a-form-item label="价值(元)" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.amount}}
							</a-form-item>
							<a-form-item label="资产照片" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.imgUrl}} -->
								<a-image :width="100" v-for="(item,index) in showFormModal.imgList" :key="index"  :src="item"></a-image>
							</a-form-item>
							<a-form-item label="使用部门" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.departmentName}}
							</a-form-item>
							<a-form-item label="使用人" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.user}}
							</a-form-item>
						</div>
					</a-col>
				</a-row>
			</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { applyKeepAccounts, getAssetsDetailSearch } from '@/service/modules/property.js';
	import { lossOrProfitDetail, postAudit } from '@/service/modules/inventory.js';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	export default {
		components: { filesUpload, modalHeader },
		data() {
			return {
				loading: false,
				reason: '盘亏',
				visible: false,
				formModal: {},
				showFormModal: {},
				fileList: [],
				assetsId: null
			}
		},
		methods: {
			async getData(id) {
				this.loading = true;
				try {
					let ret = await lossOrProfitDetail({
						id
					});
					this.loading = false;
					if (ret.code === 200) {
						// console.log("sear",ret)
						this.formModal.name = ret.data.assets.name,
						this.formModal.cause = ret.data.cause,
						this.formModal.voucherNo = ret.data.voucherNo,
						this.formModal.explain = ret.data.explain,
						this.formModal.materialUrl = ret.data.materialUrl
						this.showFormModal = ret.data.assets;
						this.showFormModal.imgList = []
						let tempStr = ret.data.assets.imgUrl
						let tempArr = tempStr? tempStr.split(','): []
						this.showFormModal.imgList.push(...tempArr)
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
					this.loading = true;
					try {
						let ret = await postAudit({
							id: this.assetsId,
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('审批成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				// })
			},
			open(e) {
				if (e) {
					// console.log('detal')
					this.getData(e.assetsRecordId || e.skipId)
					this.assetsId = e.assetsRecordId || e.skipId
				} else {
					this.showFormModal = {}
				}
				this.visible = true;
			},
			close() {
				// this.$refs.formRef.clearValidate();
				this.visible = false;
				this.formModal = {}
			}
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>