<template>
	<div>
		<a-spin :spinning="loading">
			<!-- <a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<a-button >
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
					<a-button>
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="待办类型" class="ui-form__item">
						<a-input></a-input>
					</a-form-item>
					<a-form-item label="事件单位" class="ui-form__item">
						<a-select  allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="提醒时间段" class="ui-form__item">
						<a-range-picker  @change="onChangeFun" :value="clearTime" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group>
							<a-checkbox >盘点任务名称</a-checkbox>
							<a-checkbox >资产编号</a-checkbox>
							<a-checkbox >资产名称</a-checkbox>
							<a-checkbox >价值</a-checkbox>
							<a-checkbox >是否已处理</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary">正序</a-button>
					</a-col>
				</a-row>
			</div> -->
			
			<div style="margin-top: 20px;">
				<a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<!-- <template v-if="column.key === 'type'">
							<div v-if="record.type === 1">盘亏预警</div>
							<div v-if="record.type === 2">到期预警</div>
							<div v-if="record.type === 3">借入确认</div>
							<div v-if="record.type === 4">调入确认</div>
							<div v-if="record.type === 5">盘点结果确认</div>
							<div v-if="record.type === 6">下账申请审核</div>
							<div v-if="record.type === 7">资产报废申请</div>
							<div v-if="record.type === 8">审核申请</div>
						</template> -->
						<template v-if="column.key === 'status'">
							<div v-if="record.isAudit === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button name="lossWarn" v-permission="['pc_jobs_todo_lossWarn']" v-if="record.type === 1" type="link" size="small" @click="onLossWarn(record,1)">盘亏预警</a-button>
							<a-button name="lossWarnManage" v-permission="['pc_jobs_todo_lossWarnManage']" v-if="record.type === 1" type="link" size="small" @click="onLossWarn(record,2)">盘亏预警</a-button>
							<span></span>
							<a-button name="warning" v-permission="['pc_jobs_todo_warning']" v-if="record.type === 2" type="link" size="small" @click="onWarning(record,1)">到期预警</a-button>
							<a-button name="warningManage" v-permission="['pc_jobs_todo_warningManage']" v-if="record.type === 2" type="link" size="small" @click="onWarning(record,2)">到期预警</a-button>
							<span></span>
							<a-button name="borrow" v-permission="['pc_jobs_todo_borrow']" v-if="record.type === 3" type="link" size="small" @click="onConfirm(record)">借入确认</a-button>
							<span></span>
							<a-button name="transIn" v-permission="['pc_jobs_todo_transIn']" v-if="record.type === 4" type="link" size="small" @click="onIn(record)">调入确认</a-button>
							<span></span>
							<a-button name="task" v-permission="['pc_jobs_todo_task']" v-if="record.type === 5" type="link" size="small" @click="onResult(record,1)">盘点结果</a-button>
							<a-button name="taskManage" v-permission="['pc_jobs_todo_taskManage']" v-if="record.type === 5" type="link" size="small" @click="onResult(record,2)">盘点结果</a-button>
							<span></span>
							<a-button name="accountsManage" v-permission="['pc_jobs_todo_accountsManage']" v-if="record.type === 6" type="link" size="small" @click="onLossApply(record)">下账申请</a-button>
							<span></span>
							<a-button name="scrapManage" v-permission="['pc_jobs_todo_scrapManage']" v-if="record.type === 7" type="link" size="small" @click="onDiscard(record)">报废申请</a-button>
							<span></span>
							<a-button name="profit" v-permission="['pc_jobs_todo_profit']" v-if="record.type === 8" type="link" size="small" @click="onProfit(record,1)">盘盈入库</a-button>
							<a-button name="profitManage" v-permission="['pc_jobs_todo_profitManage']" v-if="record.type === 8" type="link" size="small" @click="onProfit(record,2)">盘盈入库</a-button>
							<span></span>
							<a-button name="cancelApply" v-permission="['pc_jobs_todo_cancelApplyManage']" v-if="record.type === 9" type="link" size="small" @click="onConfirmCancel(record)">撤销申请</a-button>
							<span></span>
							<a-button name="taskArrange" v-permission="['pc_jobs_todo_taskArrange']" v-if="record.type === 10" type="link" size="small" @click="onResult(record,1)">任务下达</a-button>
							<a-button name="taskArrangeManage" v-permission="['pc_jobs_todo_taskArrangeManage']" v-if="record.type === 10" type="link" size="small" @click="onResult(record,2)">任务下达</a-button>
							<span></span>
							<a-button name="return" v-permission="['pc_jobs_todo_return']" v-if="record.type === 11" type="link" size="small" @click="onReturn(record)">归还确认</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<profitModal ref="profitModalRef" @refresh="getData"></profitModal>
		<mProfitModal ref="mProfitModalRef" @refresh="getData"></mProfitModal>
		<lossApplyModal ref="lossApplyModalRef" @refresh="getData"></lossApplyModal>
		<mLossApplyModal ref="mLossApplyModalRef" @refresh="getData"></mLossApplyModal>
		<!-- <resultModal ref="resultModalRef" @refresh="onReset"></resultModal> -->
		<!-- <mResultModal ref="mResultModalRef" @refresh="onReset"></mResultModal> -->
		<discardModal ref="discardModalRef" @refresh="getData"></discardModal>
		<mDiscardModal ref="mDiscardModalRef" @refresh="getData"></mDiscardModal>
		<!-- <inModal ref="inModalRef" @refresh="onReset"></inModal> -->
	</div>
</template>

<script>
	// import { postAudit } from '@/service/modules/inventory.js'; // 财政确认下账或报废
	import { getReturnConfirm, getConfirmBorrowOrLend } from '@/service/modules/property.js';
	import { getMyBacklogList, postConfirmRevokeApply } from '@/service/modules/jobs.js';
	import { Icon } from '@/components/icon/icon.js';
	
	// import inModal from '@/views/property/transIn/components/inModal.vue'; // 资产调入
	
	import profitModal from '@/views/inventory/components/profitModal.vue';
	import mProfitModal from '@/views/inventoryManage/components/profitModal.vue';
	
	import lossApplyModal from '@/views/inventory/components/lossApplyModal.vue';
	import mLossApplyModal from '@/views/inventoryManage/components/lossApplyModal.vue';
	
	// import resultModal from '@/views/inventory/components/resultModal.vue';
	// import mResultModal from '@/views/inventoryManage/components/resultModal.vue';
	
	import discardModal from '@/views/property/scrap/components/discardModal.vue';
	import mDiscardModal from '@/views/propertyManage/scrap/components/discardModal.vue';
	export default {
		components: { Icon, profitModal, mProfitModal, lossApplyModal, mLossApplyModal,  discardModal, mDiscardModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {},
				list: [],
				clearTime: [], // 存储时间 用来清空重置
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '待办类型',
					align: 'center',
					key: 'type',
					dataIndex: "title"
				}, {
					title: '事件单位',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '待办内容',
					align: 'center',
					dataIndex: "content"
				}, {
					title: '是否已处理',
					align: 'center',
					key: 'status',
					// dataIndex: "isAudit"
				}, {
					title: '提醒时间',
					key: 'time',
					align: 'center',
					dataIndex: "createTime"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.pagination.current = 1;
				// console.log("sear", this.searchData,this.formModal.name)
				this.getData();
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMyBacklogList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
						// console.log("单位",this.list)
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onLossApply(item) {
				this.$refs.mLossApplyModalRef.open(item);
			},
			onProfit(item,num) {
				if(num === 1) { // 1为资产
					this.$refs.profitModalRef.open(item);
				}else{	// 财政
					this.$refs.mProfitModalRef.open(item);
				}
			},
			onLossWarn(item,num) {
				if(num === 1) { // 1为资产
					// this.$refs.resultModalRef.open(item);
					this.$router.push({
						name: 'inventoryLossWarn'
					})
				}else{	// 财政
					// this.$refs.mResultModalRef.open(item);
					this.$router.push({
						name: 'warnManageLossWarn'
					})
				}
			},
			onWarning(item,num) {
				if(num === 1) { // 1为资产
					// this.$refs.resultModalRef.open(item);
					this.$router.push({
						name: 'inventoryWarning'
					})
				}else{	// 财政
					// this.$refs.mResultModalRef.open(item);
					this.$router.push({
						name: 'warnManageWarning'
					})
				}
			},
			onResult(item,num) {
				if(num === 1) { // 1为资产
					// this.$refs.resultModalRef.open(item);
					this.$router.push({
						name: 'inventoryTack'
					})
				}else{	// 财政
					this.$router.push({
						name: 'inventoryManageTack'
					})
					// this.$refs.mResultModalRef.open(item);
				}
			},
			onDiscard(item) {
				this.$refs.mDiscardModalRef.open(item);
			},
			onIn(item) { // 资产调入
				// this.$refs.inModalRef.open(item)
				this.$router.push({
					name: 'propertyTransIn'
				})
			},
			onReturn(item) { // 归还资产
				this.$confirm({
					title: '提示',
					content: "确定归还吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await getReturnConfirm({
								id: item.skipId
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('归还成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onConfirm(item) { // 确认借入资产
				this.$confirm({
					title: '提示',
					content: "确定借入吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await getConfirmBorrowOrLend({
								id: item.skipId
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('确认成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onConfirmCancel(item) { // 确认撤销下账或报废
				this.$confirm({
					title: '提示',
					content: "是否确认撤销吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await postConfirmRevokeApply({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('确认成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
</style>